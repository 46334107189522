.container-empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.container-empty2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 5%;
}

#cheakout {
  float: right;
  margin-right: 8%;
  background: #c5269d;
  width: 20%;
}

a:link,
a:visited {
  color: black;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

link:hover,
link:active,
a:hover,
a:active {
  background-color: rgb(223, 189, 204);
  color: black;
}
.header1 {
  background-color: rgb(223, 189, 204);
  border: solid rgb(223, 189, 204);
  height: 2%;
}

.header-text1 {
  font-size: 8px;
  text-align: center;
}

.header-title2 {
  margin-top: 2%;
  margin-bottom: 4%;
}

.header-text-title2 {
  text-align: center;
  border-style: solid none solid none;
  border-width: 0.1px;
  border-color: black;
  padding-top: 2%;
  padding-bottom: 2%;
  color: rgb(185, 93, 133);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
}

li {
  float: left;
}
/* 
li Link {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 14px;
  text-align: center;
  padding: 10px 30px;
  text-decoration: none;
}

li Link:hover:not(.active) {
  background-color: rgb(223, 189, 204);
  color: rgb(153, 17, 76);
}  */

.nav1-right {
  float: right;
  margin-right: 50px;
}

.active {
  background-color: #04aa6d;
}

.title {
  text-align: center;
  margin-left: 17%;
}

.admin-title {
  text-align: center;
}

.navbar1 {
  border-style: solid none solid none;
  border-width: 0.1px;
}

/* 
.mainNav {
  padding-left: 3%;
  padding-right: 3%;
} 
*/
.mainNav1 {
  margin-left: 36%;
}
.adminNav1 {
  margin-left: 2%;
}

.best-siller-card {
  margin-left: 10%;
  margin-right: 10%;
}

.login-form {
  position: relative;
  margin-left: 30%;
  margin-right: 30%;
  border-radius: 5px;
  background-color: #e6dee3;
  padding: 20px;
  opacity: 0.9;
}

.login-img {
  position: absolute;
  margin-top: 0.1%;
  height: 81%;
  width: 100%;
}

.blogImg {
  margin: -10%;
  width: 110%;
  height: 55%;
  position: absolute;
}

Button[type="submit"],
#loginbtn {
  width: 100%;
  background-color: #c5269d;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

Button[type="submit"],
#loginbtn:hover {
  background-color: #720875;
}

.div-cart {
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 3%;
}

tr {
  text-align: center;
}
.AdminAddItem {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3%;
  padding-bottom: 5%;
}

.container-cart {
  display: grid;
  grid-template-columns: 25%, 25%, 25%, 25%;
}

.container {
  display: grid;
  grid-template-columns: 1fr, 1fr, 1fr, 1fr;
  grid-template-rows: auto;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr, 1fr, 1fr, 1fr;
}

.display-img {
  grid-column-start: 1;
  grid-column-end: 2;
}

.display-content {
  grid-column-start: 2;
  grid-column-end: 3;
  margin-top: 12%;
}

.shop-col1 {
  margin-top: 40%;

  grid-column-start: 1;
  grid-column-end: 2;
}

.shop-col2 {
  grid-column-start: 2;
  grid-column-end: 4;
}

.col1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.col2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.col3 {
  grid-column-start: 3;
  grid-column-end: 4;
}
.col4 {
  grid-column-start: 4;
  grid-column-end: 5;
}
.cart-col1 {
  grid-column-start: 0;
  grid-column-end: 1;
  margin-left: -40%;
  margin-right: 20%;
}
.cart-col2 {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-left: -40%;
  margin-left: -610%;
}
.cart-col3 {
  grid-column-start: 2;
  grid-column-end: 3;
  margin-left: -440%;
}
.cart-col4 {
  grid-column-start: 3;
  grid-column-end: 4;
  margin-left: -160%;
}

.cartTotal {
  text-align: right;
  margin-right: 10%;
}
.hr-cart {
  margin-left: 8%;
  margin-right: 8%;
}
.hr-cart1 {
  margin-left: 9%;
  margin-right: -2%;
}

.imd-display {
  height: 70%;
}

.filter {
  background-color: #c5269d;
}

.total {
  grid-column-start: 4;
  grid-column-end: 5;
}

.copon {
  grid-column-start: 3;
  grid-column-end: 4;
}

.error {
  margin: 10%;
  text-align: center;
  position: relative;
}

.errorImg {
  margin: -140px;
  width: 110%;
  height: 82%;
  position: absolute;
}

/* =================================================== */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.prands-img {
  padding-top: 3%;
  padding-left: 15%;
  padding-right: 15%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
